<template>
  <div>
    <pl-table
      :data="tableData"
      border
      stripe
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
    >
      <el-table-column label="类别" prop="pName" show-overflow-tooltip></el-table-column>
      <el-table-column label="名称" prop="typeName" show-overflow-tooltip></el-table-column>
      <el-table-column label="金额" prop="amount" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.amount|thousands(2)}}</template>
      </el-table-column>
    </pl-table>
  </div>
</template>

<script>
import tableHeight from "@/common/tableHeightMixins";
export default {
  mixins: [tableHeight],
  props: ["tableData"]
};
</script>

<style>
</style>