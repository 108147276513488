<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh">刷新</el-button>
        <el-button
          type="success"
          @click="excel"
          :disabled="activeName=='General'||!filter.projectId"
        >
          <span class="iconfont iconexcel" style="font-size:12px;"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="项目概况" name="General">
        <el-card>
          <el-row class="general-row">
            <el-col :span="6">
              <span>项目编号：</span>
              <span class="general-item">{{General.billCode}}</span>
            </el-col>
            <el-col :span="6">
              <span>立项日期：</span>
              <span class="general-item">{{General.billDate|datetime}}</span>
            </el-col>
            <el-col :span="6">
              <span>开始日期：</span>
              <span class="general-item">{{General.startDate|datetime}}</span>
            </el-col>
            <el-col :span="6">
              <span>结束日期：</span>
              <span class="general-item">{{General.endDate|datetime}}</span>
            </el-col>
          </el-row>
          <el-row class="general-row">
            <el-col :span="12">
              <span>项目名称：</span>
              <span class="general-item">{{General.projectName}}</span>
            </el-col>
            <el-col :span="6">
              <span>项目状态：</span>
              <span class="general-item">{{getBillStateLabel(General.billState)}}</span>
            </el-col>
          </el-row>
          <el-row class="general-row">
            <el-col :span="6">
              <span>合同金额：</span>
              <span class="general-item">{{General.contractAmount|amount|thousands}}</span>
            </el-col>
            <el-col :span="6">
              <span>合同收款：</span>
              <span class="general-item">{{General.recAmount|amount|thousands}}</span>
            </el-col>
            <el-col :span="6">
              <span>合同毛利：</span>
              <el-tooltip content="合同金额-费用总计" placement="top">
                <span class="general-item">{{General.gross|amount|thousands}}</span>
              </el-tooltip>
            </el-col>
          </el-row>
          <el-row class="general-row">
            <el-col :span="6">
              <span>物资预算：</span>
              <span class="general-item">{{General.goodsBudget|thousands}}</span>
            </el-col>
            <el-col :span="6">
              <span>费用预算：</span>
              <span class="general-item">{{General.expenseBudget|thousands}}</span>
            </el-col>
            <el-col :span="6">
              <span>人工预算：</span>
              <span class="general-item">{{General.laborBudget|thousands}}</span>
            </el-col>
            <el-col :span="6">
              <span>预算控制：</span>
              <span class="general-item">{{General.budgetControl?'控制':'不控制'}}</span>
            </el-col>
          </el-row>
          <el-row class="general-row">
            <el-col :span="6">
              <span>物资材料：</span>
              <span class="general-item">{{General.goodsAmount|amount|thousands}}</span>
            </el-col>
            <el-col :span="6">
              <span>费用开支：</span>
              <span class="general-item">{{General.expenseAmount|amount|thousands}}</span>
            </el-col>
            <el-col :span="6">
              <span>人工成本：</span>
              <span class="general-item">{{General.laborAmount|amount|thousands}}</span>
            </el-col>
            <el-col :span="6">
              <span>费用总计：</span>
              <span class="general-item">{{General.totalAmount|amount|thousands}}</span>
            </el-col>
          </el-row>
          <el-row class="general-row">
            <el-col :span="24">
              <span>项目地址：</span>
              <span class="general-item">{{General.address}}</span>
            </el-col>
          </el-row>
          <el-row class="general-row">
            <el-col :span="24">
              <span>项目说明：</span>
              <span class="general-item">{{General.remark}}</span>
            </el-col>
          </el-row>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="项目合同" name="Contract">
        <el-table :data="Contract" stripe border>
          <el-table-column label="合同编号" show-overflow-tooltip prop="billCode"></el-table-column>
          <el-table-column label="合同日期" prop="billDate" show-overflow-tooltip></el-table-column>
          <el-table-column label="合同名称" prop="contractName" show-overflow-tooltip></el-table-column>
          <el-table-column label="客户" prop="clientName" show-overflow-tooltip></el-table-column>
          <el-table-column label="合同金额" prop="contractAmount" show-overflow-tooltip>
            <template slot-scope="scope">{{scope.row.contractAmount|thousands(2)}}</template>
          </el-table-column>
          <el-table-column label="结算金额" prop="balanceAmount" show-overflow-tooltip>
            <template slot-scope="scope">{{scope.row.balanceAmount|thousands(2)}}</template>
          </el-table-column>
          <el-table-column label="剩余金额" prop="surplus" show-overflow-tooltip>
            <template slot-scope="scope">{{scope.row.surplus|thousands(2)}}</template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="物资材料" name="Goods">
        <goods-detail :tableData="Goods"></goods-detail>
      </el-tab-pane>
      <el-tab-pane label="费用开支" name="Expense">
        <expense-detail :tableData="Expense"></expense-detail>
      </el-tab-pane>
      <el-tab-pane label="人工成本" name="Labor">
        <labor-detail :tableData="Labor"></labor-detail>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ProjectSelect from "@/components/ProjectSelect.vue";
import billState from "@/views/project/BillState.js";
import setName from "@/common/setName";
import GoodsDetail from "./Goods";
import ExpenseDetail from "./Expense";
import LaborDetail from "./Labor";
import toExcel from "@/common/toExcel";
export default {
  components: {
    ProjectSelect,
    GoodsDetail,
    ExpenseDetail,
    LaborDetail
  },
  data() {
    return {
      filter: {},
      activeName: "General",
      General: {},
      Contract: [],
      Goods: [],
      Expense: [],
      Labor: []
    };
  },
  activated() {
    let item = this.$store.state.reportfilter.cache.find(
      r => r.name == this.$route.name
    );
    if (item) {
      this.filter = { ...item.filter };
      this.getData();
    }
  },
  watch: {
    activeName: {
      handler: function(item) {
        this.getData();
      }
    }
  },
  methods: {
    getBillStateLabel(value) {
      let res = "";
      let item = billState.find(r => r.value == value);
      if (item) {
        res = item.label;
      }
      return res;
    },
    getData() {
      this.$store.commit("deleteFilterCache", this.$route.name);
      let activeName = this.activeName;
      let projectId = this.filter.projectId;
      if (!activeName) {
        return;
      }
      if (!projectId) {
        this.$message.error("请选择项目");
        return;
      }

      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$get(`ProjectSynthesize/${activeName}?id=${projectId}`)
        .then(r => {
          switch (activeName) {
            case "General":
              r = r[0] || {};
              r.totalAmount = r.goodsAmount + r.expenseAmount + r.laborAmount;
              r.gross = r.contractAmount - r.totalAmount;
              break;
            case "Contract":
              r.forEach(row => {
                row.billDate = this.$moment(row.billDate).format("YYYY-MM-DD");
                row.surplus = this.amountFormat(
                  row.contractAmount - row.balanceAmount
                );
                setName("Client", row, "clientId", "clientName");
              });
            case "Goods":
              r.forEach(item => {
                item.amount = this.amountFormat(item.amount);
                item.price =
                  item.quantity == 0 ? 0 : item.amount / item.quantity;
              });
              break;
            case "Labor":
              r.forEach(item => {
                item.amount = this.amountFormat(item.amount);
                item.price =
                  item.quantity == 0 ? 0 : item.amount / item.quantity;
              });
              break;
          }
          this[activeName] = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    excel() {
      let project = {
        projectId: this.filter.projectId,
        projectName: ""
      };
      setName("Project", project, "projectId", "projectName");
      let activeName = this.activeName;
      let list = this[activeName];
      let excelName = "";
      let header;
      let column;
      switch (activeName) {
        case "Contract":
          excelName = "项目合同";
          header = [
            "合同编号",
            "合同日期",
            "合同名称",
            "客户",
            "合同金额",
            "结算金额",
            "剩余金额"
          ];
          column = [
            "billCode",
            "billDate",
            "contractName",
            "clientName",
            "contractAmount",
            "balanceAmount",
            "surplus"
          ];
          break;
        case "Goods":
          excelName = "物资材料";
          header = [
            "类别",
            "编号",
            "名称",
            "规格",
            "单位",
            "数量",
            "单价",
            "金额"
          ];
          column = [
            "typeName",
            "goodsCode",
            "goodsName",
            "goodsSpec",
            "goodsUnit",
            "quantity",
            "price",
            "amount"
          ];
          break;
        case "Expense":
          excelName = "费用开支";
          header = ["类别", "名称", "金额"];
          column = ["pName", "typeName", "amount"];
          break;
        case "Labor":
          excelName = "人工成本";
          header = ["工种", "工时", "工时费", "金额"];
          column = ["typeName", "quantity", "price", "amount"];
          break;
      }
      toExcel(header, column, list, `${project.projectName}-${excelName}`);
    }
  }
};
</script>

<style scoped>
.general-row {
  margin-bottom: 20px;
  font-size: 14px;
}
.general-item {
  margin-left: 8px;
  color: #67c23a;
}
</style>