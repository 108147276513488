<template>
  <div>
    <pl-table
      :data="tableData"
      border
      stripe
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
    >
      <el-table-column label="类别" prop="typeName" show-overflow-tooltip></el-table-column>
      <el-table-column label="编号" prop="goodsCode" show-overflow-tooltip></el-table-column>
      <el-table-column label="名称" prop="goodsName" show-overflow-tooltip></el-table-column>
      <el-table-column label="规格" prop="goodsSpec" show-overflow-tooltip></el-table-column>
      <el-table-column label="单位" prop="goodsUnit" show-overflow-tooltip></el-table-column>
      <el-table-column label="数量" prop="quantity" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.quantity|thousands}}</template>
      </el-table-column>
      <el-table-column label="单价" prop="price" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.price|thousands}}</template>
      </el-table-column>
      <el-table-column label="金额" prop="amount" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.amount|thousands(2)}}</template>
      </el-table-column>
    </pl-table>
  </div>
</template>

<script>
import tableHeight from "@/common/tableHeightMixins";
export default {
  mixins: [tableHeight],
  props: ["tableData"]
};
</script>

<style>
</style>